import { IBackgroundImageProps } from 'gatsby-background-image'
import React from 'react'
import SmsUI from './Sms.ui'

interface SmsProps {
  imgAlt: string
  imgFluid: IBackgroundImageProps['fluid']
  /**
   * TODO: Consider how layout container and layout presentation
   * components can be swapped out for each other while maintaining
   * strong typing so that Storybook can use the presentational
   * layout and our app can use the container layout.
   */
  Layout: (props: any) => JSX.Element
}

/**
 * Implements the SMS OptIn Landing Page
 */

export default function Sms({ imgFluid, imgAlt, Layout }: SmsProps) {
  return (
    <Layout>
      <SmsUI
        body="Get notified the instant your box arrives, be the first to know
        about special offers, & more! Sign up for ButcherBox™ SMS
        notifications today."
        heading="Your box could be waiting…"
        imgAlt={imgAlt}
        imgFluid={imgFluid}
        variant="lander"
      />
    </Layout>
  )
}
