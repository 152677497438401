import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import AuthenticatedLayout from '~/layouts/AuthenticatedLayout'
import Sms from '~/routes/Sms/Lander'
import { cleanJsonStrings } from '~/utils/unicode'

export default function () {
  const { optInImage } = cleanJsonStrings(
    useStaticQuery<{ optInImage: any }>(graphql`
      query {
        optInImage: file(relativePath: { eq: "sms_lander_optin_updated.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  )

  return (
    <Sms
      imgAlt="An SMS message on a mobile phone reading: ButcherBox: Your ButcherBox has just been Delivered 📦🏠"
      imgFluid={optInImage?.childImageSharp?.fluid}
      Layout={AuthenticatedLayout}
    />
  )
}
